import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import deductionStyle from '../../assets/jss/material-dashboard-pro-react/pages/deductionStyle';

class Deduction extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          <div className={classes.subContainer}>
            <div className={classes.section}>
              <Typography className={classes.text}>Basic</Typography>
              <Typography className={classes.text}>20000</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.text}>HRA</Typography>
              <Typography className={classes.text}>2500</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.text}>Conveyance</Typography>
              <Typography className={classes.text}>0</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.text}>Education Allowance</Typography>
              <Typography className={classes.text}>0</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.text}>Medical</Typography>
              <Typography className={classes.text}>0</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.text}>Special Allowance</Typography>
              <Typography className={classes.text}>6681</Typography>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(deductionStyle)(Deduction);
