/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
import React, { Component } from "react";
import CustomButtons from "../../atoms/CustomButtons/CustomButtons";
import { Typography, withStyles, IconButton } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import StopIcon from "@material-ui/icons/Stop";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import differenceInDays from "date-fns/differenceInDays";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import clsx from "clsx";
import startOfMonth from "date-fns/startOfMonth";
import attendenceStyle from "../../assets/jss/material-dashboard-pro-react/pages/attendenceStyle";

const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      container: {
        background: "#F0F5F2",
        alignItems: "center",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        display: "none",
      },
      dayLabel: {
        color: "#111111",
      },
    },
  },
});

let arr = [];

const attendanceSummaryPossibleValues = {
  abs: "#FC6264",
  dp: "#057D33",
  lwp: "#FB17FB",
  wo: "#FCDF5D",
  wop: "#5CE1E6",
  cl: "#FEBD57",
  co_plus: "#2986cc",
  co_minus: "#674ea7",
  od: "#54d3a8",
  pl: "#C9E165",
  ph: "#db247f",
  php: "#f5922b",
  rh: "#f55a2b",
  sl: "#2b99f5",
  l2: "#f52b81",
};

const dateObj = Object.keys(attendanceSummaryPossibleValues).map((key) => ({
  purpose: key,
  dates: [],
}));

class Attendence extends Component {
  constructor(props) {
    super(props);

    /**
     * [{25-10-2021: 'lwp'}, {26-10-2021: 'lwp'}] =>
     * {lwp: [25-10-2021, 26-10-2021]}
     */
    const attendanceObj = props.days.reduce((acc, curr) => {
      const date = Object.keys(curr)?.[0];
      const purpose = Object.values(curr)?.[0];
      purpose?.split(",")?.forEach((p) => {
        acc[p] = [...(acc[p] || []), date];
      });
      return acc;
    }, {});

    Object.entries(attendanceObj).forEach(
      ([purpose, dates]) =>
        (dateObj.find((date) => date.purpose === purpose).dates = dates.map(
          (date) => new Date(date.split("-").reverse().join("/"))
        ))
    );
    arr = dateObj
      .flatMap((curr) => {
        return curr.dates.map((d) => {
          return { date: d, purpose: curr.purpose };
        });
      })
      .sort((a, b) => differenceInDays(a.date, b.date));

    this.state = {
      selectedDate: arr?.[0]?.date || new Date(),
    };
  }

  handleMonthChange = (date) => {
    this.setState({ selectedDate: startOfMonth(new Date(date)) });
  };

  renderWrappedMonthDay = (date, _, dayInCurrentMonth) => {
    const { classes } = this.props;
    let dateClone = new Date(date);
    // let dateIndex, purpose;

    const days = arr.filter(({ date: arrDate }) => {
      if (isSameDay(dateClone, arrDate)) {
        // dateIndex = index;
        // purpose = date.purpose;
        return isSameDay(dateClone, arrDate);
      }
    });

    const checkDate = arr?.find(({ date: arrDate }) => {
      if (isSameDay(dateClone, arrDate)) {
        // dateIndex = index;
        // purpose = date.purpose;
        return isSameDay(dateClone, arrDate);
      }
    });

    const purposeArr = arr.filter(
      (curr) => checkDate?.purpose === curr.purpose
    );

    const purposeArrIndex = purposeArr.findIndex(
      (date) => date.date === checkDate.date
    );

    const isPreviousDay =
      differenceInDays(
        purposeArr[purposeArrIndex - 1]?.date,
        checkDate?.date
      ) === -1;

    const isNextDay =
      differenceInDays(
        purposeArr[purposeArrIndex + 1]?.date,
        checkDate?.date
      ) === 1;

    const isNextWeek = purposeArr.some((curr, index) =>
      isSameDay(curr.date, addDays(checkDate?.date, 7))
    );

    const isLastWeek = purposeArr.some((curr) =>
      isSameDay(curr.date, subDays(checkDate?.date, 7))
    );

    const isFirst = purposeArrIndex === 0;

    const isLast = purposeArrIndex === purposeArr.length - 1;

    // const isPartOfRow = (date, index) => {
    //   return index !== 0 && differenceInDays(purposeArr[index - 1]?.date, date) === -1 || index !== purposeArr.length - 1 && differenceInDays(purposeArr[index + 1]?.date, date) === 1
    // }

    // const isMulti = days.length > 1;
    // const isPreviousDayMulti = subDays(
    //   purposeArr[purposeArrIndex - 1]?.date,
    //   checkDate?.date
    // ) === -1;
    // const isNextDayMulti = days.length > 1;
    // const isPreviousWeekMulti = days.length > 1;
    // const isNextWeekMulti = days.length > 1;

    const wrapperClassName = clsx({
      // [classes.customBorderBottomLeft]:
      //   (isLast && !isPreviousDay) ||
      //   (!isNextWeek && isFirst) ||
      //   (purposeArrIndex > 0 && !isPreviousDay && !isNextWeek),
      // [classes.customBorderBottomRight]:
      //   isLast ||
      //   (!isNextWeek && !isNextDay) ||
      //   (purposeArrIndex > 0 && !isNextDay && !isNextWeek),
      // [classes.customBorderTopLeft]:
      //   isFirst ||
      //   (purposeArrIndex > 0 &&
      //     differenceInDays(
      //       purposeArr[purposeArrIndex - 1].date,
      //       checkDate.date
      //     ) !== -1 &&
      //     !isLastWeek),
      // [classes.customBorderTopRight]:
      //   (isLast && !isLastWeek) ||
      //   (purposeArrIndex < purposeArr.length - 1 &&
      //     differenceInDays(
      //       purposeArr[purposeArrIndex + 1].date,
      //       checkDate.date
      //     ) !== 1 &&
      //     !isLastWeek),
      [classes.customBorderBottomLeft]: checkDate?.date,
      [classes.customBorderBottomRight]: checkDate?.date,
      [classes.customBorderTopLeft]: checkDate?.date,
      [classes.customBorderTopRight]: checkDate?.date,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && checkDate,
    });

    return (
      <div
        className={wrapperClassName}
        style={{
          background: checkDate
            ? days.length > 1
              ? `linear-gradient(to right, ${
                  attendanceSummaryPossibleValues[days?.[0]?.purpose]
                } 50%, ${
                  attendanceSummaryPossibleValues[days?.[1]?.purpose]
                } 50%)`
              : attendanceSummaryPossibleValues[checkDate.purpose]
            : undefined,
          color: checkDate ? "#fff" : undefined,
        }}
      >
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.dateContainer}>
          <ThemeProvider theme={datePickerTheme}>
            <DatePicker
              readOnly
              disableToolbar
              value={this.props.month}
              onChange={this.handleMonthChange}
              renderDay={this.renderWrappedMonthDay}
              variant="static"
              openTo="date"
            />
          </ThemeProvider>
          <div>
            {this.props.summary?.some(
              (summary) => summary.key !== "abs" && summary.key !== "lwp"
            ) && (
              <Typography
                style={{
                  marginBottom: "-10px",
                  marginTop: "20px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Days Paid
              </Typography>
            )}
            <div className={classes.container}>
              {this.props.summary
                ?.filter(
                  (summary) => summary.key !== "abs" && summary.key !== "lwp"
                )
                ?.map((summary) => (
                  <div className={classes.legend} key={summary.key}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          color: attendanceSummaryPossibleValues[summary.key],
                        }}
                      >
                        <StopIcon />
                      </div>
                      <Typography
                        className={classes.labelText}
                        style={{ fontSize: "12px" }}
                      >
                        {summary.value}
                      </Typography>
                    </div>
                    <Typography
                      className={classes.text}
                      style={{ fontSize: "12px" }}
                    >
                      {summary.label}
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
          <div>
            {this.props.summary?.some(
              (summary) => summary.key === "abs" || summary.key === "lwp"
            ) && (
              <Typography
                style={{
                  marginBottom: "-10px",
                  marginTop: "5px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Days Unpaid
              </Typography>
            )}
            <div className={classes.container}>
              {this.props.summary
                ?.filter(
                  (summary) => summary.key === "abs" || summary.key === "lwp"
                )
                ?.map((summary) => (
                  <div className={classes.legend} key={summary.key}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          color: attendanceSummaryPossibleValues[summary.key],
                        }}
                      >
                        <StopIcon />
                      </div>
                      <Typography
                        className={classes.labelText}
                        style={{ fontSize: "12px" }}
                      >
                        {summary.value}
                      </Typography>
                    </div>
                    <Typography
                      className={classes.text}
                      style={{ fontSize: "12px" }}
                    >
                      {summary.label}
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
          {/* <div className={classes.buttonContainer}>
            <CustomButtons
              fullWidth
              danger
              className={classes.button2}
              onClick={this.props.handleOpenQuery}
            >
              Raise Query
            </CustomButtons>
          </div> */}
        </div>
      </>
    );
  }
}

export default withStyles(attendenceStyle)(Attendence);
