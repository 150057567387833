import React from "react";
import Grid from '@material-ui/core/Grid';
import { IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function Settings({ ...props }) {
  const { history } = props;

  return (
    <>
      <div style={{ padding: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Settings</Typography>
        </div>
      </div>
    </>
  );
}
