/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */


import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showErrorMessage,
  showSuccessMessage
} from '../../../utils/utility';

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showErrorMessage,
  showSuccessMessage
};

export const POST_SEND_OTP_API = `${API_URL}/app/sendotp`;
export const POST_VERIFY_OTP_API = `${API_URL}/app/verifyotp`;
export const GET_REFRESH_TOKEN_API = `${API_URL}/app/refreshtoken`;

export const LoginActionTypes = {
  post_sendOTP: createRequestActionTypes('POST_SEND_OTP'),
  post_verifyOTP: createRequestActionTypes('POST_VERIFY_OTP'),
  get_refreshToken: createRequestActionTypes('GET_REFRESH_TOKEN')
};