const userProfileStyle = theme => ({
  card: {
    height: '179px'
  },
  topContainer: {
    padding: '0px 20px',
    height: '100%'
  },
  subContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem'
  },
  text: {
    marginLeft: '0.5rem'
  },
  text1: {
    marginLeft: '0.5rem'
  },
  text2: {
    lineHeight: 1
  },
  text3: {
    color: '#000000',
    opacity: 0.6
  },
  text4: {
    marginLeft: '1rem'
  },
  text5: {
    marginLeft: 'auto'
  },
  cardSection: {
    display: 'flex',
    marginTop: '1.5rem',
    marginLeft: '1.5rem'
  },
  cardSubSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  container: {
    padding: '10px 0px',
    cursor: 'pointer'
  },
  title: {
    color: '#000',
    opacity: 0.7
  },
  titleContainer: {
    display: 'flex',
    padding: '15px 0',
    alignItems: 'center'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem'
  },
  avatar: {
    height: '70px',
    width: '70px',
    border: '4px solid #fff'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  link: {
    color: '#000',
    textDecoration: 'none'
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0'
  },
  arrowBack: {
    color: '#fff'
  },
  arrowBackIcon: {
    marginRight: '0.5rem'
  }
})

export default userProfileStyle;