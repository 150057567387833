const raisedQueryStyle = theme => ({
  card: {
    padding: '20px',
    marginTop: '1rem',
    border: 'none',
    background: '#F7F7F7',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.15)',
  },
  cardContent: {
    padding: '2px 5px',
    background: 'rgba(41, 108, 69, 0.1)'
  },
  cardText: {
    fontSize: '12px',
    color: '#296C45'
  },
  topContainer: {
    padding: '20px'
  },
  subContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: '#000',
    opacity: 0.8,
    fontSize: '16px'
  },
  labelText: {
    color: '#000',
    opacity: 0.8,
    marginTop: '1rem',
    fontSize: '12px'
  },
  valueText: {
    color: '#000',
    opacity: 0.6,
    fontSize: '12px'
  },
  autoMargin: {
    margin: 'auto'
  }
})

export default raisedQueryStyle;