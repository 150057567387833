import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import grossSalaryStyle from "../../assets/jss/material-dashboard-pro-react/pages/grossSalaryStyle";

class GrossSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, data = [] } = this.props;

    return (
      <>
        <div className={classes.container}>
          <div className={classes.subContainer}>
            {data.map((item) => (
              <div className={classes.section} key={item.label}>
                <Typography className={classes.text}>{item.label}</Typography>
                {item.value && (
                  <Typography className={classes.text}>{item.value}</Typography>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(grossSalaryStyle)(GrossSalary);
