import React from "react";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const formatAmount = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 0,
});

const MyAccordion = ({ children, title, icon, bg_color, amount }) => {
  const accordionStyle = makeStyles({
    root: {
      width: "100%",
      minHeight: "60px",
      borderRadius: "4px",
      margin: "0.5rem 0",
      overflow: "hidden",
      "& .MuiAccordion-root": {
        boxShadow: "none",
        "&.Mui-expanded": {
          margin: 0,
        },
      },
      "& .MuiAccordionSummary-root": {
        padding: "10px",
        background: bg_color,
        height: "60px",
        "&.Mui-expanded": {
          minHeight: "60px",
        },
      },
      "& .MuiAccordionSummary-content": {
        padding: "0px",
        margin: "0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .MuiAccordionDetails-root": {
        display: "unset",
        padding: "0px",
      },
      "& .MuiAccordionSummary-expandIcon": {
        "&.MuiIconButton-root": {
          color: "#fff",
        },
        "&.MuiIconButton-edgeEnd": {
          marginRight: "0px",
        },
      },
    },
    title: {
      fontSize: "16px",
      fontWeight: 500,
    },
    text1: {
      fontSize: "16px",
      color: "#fff",
      fontWeight: 500,
    },
    text2: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#fff",
    },
  });

  const classes = accordionStyle();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div
            style={{
              display: "grid",
              gridGap: "10px",
              alignItems: "center",
              gridAutoFlow: "column",
            }}
          >
            <img src={icon} alt="icon" />
            <Typography className={classes.text1}>{title}</Typography>
          </div>
          {!!amount && (
            <div className={classes.section}>
              <Typography className={classes.text2}>
                {formatAmount.format(amount)}
              </Typography>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MyAccordion;
