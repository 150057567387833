import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const CustomAvatar = (props) => {
  return (
    <Avatar
      {...props}
      alt={props.userDetails.fullname}
      style={{ background: '#296C45', cursor: 'pointer' }}
      src="/static/images/avatar/1.jpg" />
  );
}

export default CustomAvatar;