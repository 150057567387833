const PopperLinksStyle = theme => ({
  card: {
    padding: theme.spacing(2),
    border: 'none',
    background: '#fff',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.15)',
    width: '200px',
    [theme.breakpoints.only('xs')]: {
      width: '130px',
      height: '110px'
    }
  },
  list: {
    '& li:hover': {
      backgroundColor: '#196c61'
    },
  },
  divider: {
    margin: '1rem 0',
    background: '#000000, 10%'
  },
  item: {
    [theme.breakpoints.only('xs')]: {
      minHeight: 0
    }
  },
  headerContainer: {
    display: 'flex',
    jusifyContent: 'flex-start'
  },
  headerSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem'
  }
});

export default PopperLinksStyle;