const appraisalModalStyle = theme => ({
  root: {
    '& .MuiDialog-paperFullScreen': {
      top: '30%',
      maxWidth: '500px',
      margin: 'auto',
      borderTopLeftRadius: '30px',
      borderTopRightRadius: '30px'
    }
  },
  appBar: {
    position: 'relative',
    background: '#296C45'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button2: {
    marginRight: '0.5rem'
  }
})

export default appraisalModalStyle;