import React from 'react';
import CustomButtons from '../../../atoms/CustomButtons/CustomButtons';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DialogActions, withStyles } from '@material-ui/core';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import raiseQueryModalStyle from 'assets/jss/material-dashboard-pro-react/components/raiseQueryModalStyle';

const validationSchema = Yup.object({
  clientname: Yup.string(),
  salesname: Yup.string()
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RaiseQueryModal = ({ open, handleClose, ...rest }) => {
  const { classes } = rest;
  const onSubmit = () => { };

  return (
    <Formik
      initialValues={{
        clientname: "",
        salesname: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div>
          <Dialog className={classes.root} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  Raise Query
            </Typography>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className={classes.textContainer}>
              <Typography>Are you sure you want to raise a query?</Typography>
            </div>
            <DialogActions>
              <CustomButtons onClick={handleClose} fullWidth secondary>Cancel</CustomButtons>
              <CustomButtons onClick={handleClose} fullWidth danger className={classes.button2}>Submit</CustomButtons>
            </DialogActions>
          </Dialog>
        </div>
      </Form>
    </Formik>
  );
}

export default withStyles(raiseQueryModalStyle)(RaiseQueryModal)