const buttonStyle = theme => ({
  button: {
    border: 0,
    borderRadius: 8,
    color: "#FFFFFF",
    textTransform: "capitalize",
    padding: "0.5rem 30px",
    height: 50,
  },
  primary: {
    background: '#0D3A20',
    '&:hover': {
      background: '#0D3A20'
    }
  },
  secondary: {
    background: 'rgba(41, 108, 69, 0.17)',
    color: '#111111',
    opacity: 0.6,
    '&:hover': {
      background: 'rgba(41, 108, 69, 0.17)'
    }
  },
  danger: {
    background: '#E04339',
    '&:hover': {
      background: '#E04339'
    }
  },
  fullWidth: {
    width: "100%"
  },
})

export default buttonStyle;