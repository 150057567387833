/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */


import {
  actionCreator,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showErrorMessage,
  showSuccessMessage
} from '../../../utils/utility';

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showErrorMessage,
  showSuccessMessage
};

export const GET_PAYSLIP_DETAILS_BY_DATE = "https://hrtestapp.wellnessforever.com/app/employee/payslip";

export const PayslipActionTypes = {
  get_PayslipDetailsByDate: createRequestActionTypes('GET_PAYSLIP_DETAILS_BY_DATE'),
};