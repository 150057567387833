const attendenceStyle = (theme) => ({
  dateContainer: {
    border: "1px solid #AEA2FC",
    padding: "10px",
  },
  container: {
    padding: "10px 0",
    display: "flex",
    flexWrap: "wrap",
  },
  legend: {
    display: "grid",
    alignItems: "center",
    padding: "10px 0",
  },
  bottomContainer: {
    display: "flex",
    borderRadius: "4px",
    justifyContent: "space-between",
    background: "#296C45",
    padding: "20px",
    margin: "0.5rem 0",
  },
  buttonContainer: {
    display: "flex",
    marginTop: "0.5rem",
  },
  image: {
    cursor: "pointer",
  },
  autoMargin: {
    margin: "auto",
  },
  labeltext: {
    marginLeft: "5px",
  },
  text: {
    // marginLeft: "auto",
    marginLeft: "5px",
  },
  text1: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: 700,
  },
  button1: {
    marginRight: "0.5rem",
  },
  button2: {
    margin: "auto",
  },
  present: {
    fill: "#296C45",
  },
  overtime: {
    fill: "#FF8341",
  },
  leave: {
    fill: "#76A9FF",
  },
  lwp: {
    fill: "#EB5757",
  },
  weekend: {
    fill: "#F9C040",
  },

  //datepicker styles
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#fff",
  },
  lwpColor: {
    background: "#EB5757",
    color: "#fff",
  },
  presentColor: {
    background: "#296C45",
    color: "#fff",
  },
  weekendColor: {
    background: "#F9C040",
    color: "#fff",
  },
  paidLeaveColor: {
    background: "#76A9FF",
    color: "#fff",
  },
  overtimeColor: {
    background: "#FF8341",
    color: "#fff",
  },
  halfLeaveColor: {
    background: "linear-gradient(to right, #EB5757 50%, #296C45 50%)",
    color: "#fff",
  },
  customBorderBottomLeft: {
    borderBottomLeftRadius: "44px",
  },
  customBorderBottomRight: {
    borderBottomRightRadius: "44px",
  },
  customBorderTopLeft: {
    borderTopLeftRadius: "44px",
  },
  customBorderTopRight: {
    borderTopRightRadius: "44px",
  },
});

export default attendenceStyle;
