import React, { Component } from "react";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Card from '../../atoms/Card/Card';
import { Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BackArrow from '../../assets/icons/BackArrow.svg';
import { GetUserDetailsAction } from '../UserProfile/actions/UserProfileActions';
import personalDetailsStyle from "assets/jss/material-dashboard-pro-react/pages/personalDetailsStyle";

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount = () => {
    this.props.GetUserDetailsAction()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.userDetails !== undefined && this.props.userDetails !== null && this.props.userDetails !== prevProps.userDetails) {
      this.setState({
        data: this.props.userDetails
      })
    }
  }

  render() {
    const { data } = this.state;
    const { history, classes, loggedInUserDetails } = this.props;

    return (
      <>
        <div className={classes.topContainer}>
          <div className={classes.container}>
            <img src={BackArrow} className={classes.image} onClick={() => history.goBack()} />
            <Typography className={classes.autoMargin} variant="h6">Personal Details</Typography>
          </div>
          <Card className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.labelTextBold} variant="subtitle1">Name</Typography>
                <Typography className={classes.valueText} variant="subtitle1">{data.fullname}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.labelTextBold}>DOB</Typography>
                <Typography className={classes.valueText} variant="subtitle1">{moment(loggedInUserDetails.dob).format('MM-DD-YYYY')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.labelTextBold} variant="subtitle1">Gender</Typography>
                <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.sex}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.labelTextBold} variant="subtitle1">Mobile</Typography>
                <Typography className={classes.valueText} variant="subtitle1">{data.mobile}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.labelTextBold}>PAN Number</Typography>
                <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.panno}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.labelTextBold} variant="subtitle1">Address</Typography>
                <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.address}</Typography>
              </Grid>
            </Grid>
          </Card>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.userProfileReducer.userDetails !== null && state.userProfileReducer.userDetails.data,
    loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      GetUserDetailsAction,
    }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(personalDetailsStyle)(PersonalDetails));
