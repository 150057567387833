import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, withStyles } from "@material-ui/core";
import BackArrow from '../../assets/icons/BackArrow.svg';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Card from '../../atoms/Card/Card';
import { GetUserBankDetailsAction } from '../UserProfile/actions/UserProfileActions';
import bankAccountDetailsStyle from '../../assets/jss/material-dashboard-pro-react/pages/bankAccountDetailsStyle';

class BankAccountDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount = () => {
    this.props.GetUserBankDetailsAction()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.userBankDetails !== undefined && this.props.userBankDetails !== null && this.props.userBankDetails !== prevProps.userBankDetails) {
      this.setState({
        data: this.props.userBankDetails
      })
    }
  }

  render() {
    const { data } = this.state;
    const { classes, history, userDetails } = this.props;

    return (
      <>
        <div className={classes.topContainer}>
          <div className={classes.subContainer}>
            <img src={BackArrow} onClick={() => history.goBack()} />
            <Typography className={classes.autoMargin} variant="h6">Bank Account Details</Typography>
          </div>
          <Grid container >
            <Grid item xs={12}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.container}>
                      <div className={classes.headerContainer}>
                        <AccountBalanceIcon className={classes.icon} />
                        <Typography className={classes.bankName}>{data.bankname}</Typography>
                      </div>
                      <Typography className={classes.IFSCCode}>IFSC {data.ifsccode}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.bottomContainer}>
                      <Typography className={classes.cardLabel}>Account Number:</Typography>
                      <Typography className={classes.cardNumber}>{data.accountnumber}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.cardName}>{userDetails.fullname}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}


function mapStateToProps(state) {
  return {
    userBankDetails: state.userProfileReducer.userBankDetails !== null && state.userProfileReducer.userBankDetails.data,
    userDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      GetUserBankDetailsAction
    }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(bankAccountDetailsStyle)(BankAccountDetails));
