const navBarStyle = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center'
  },
  list: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    padding: theme.spacing(3)
  },
  linkContainer: {
    width: '100%',
  },
  navLink: {
    height: '82px',
    minWidth: '101px',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #296C45',
    borderRadius: '8px',
    marginRight: '1.5rem',
    textDecoration: 'none',
    color: '#296C45'
  },
  activeLink: {
    height: '82px',
    minWidth: '101px',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #296C45',
    borderRadius: '8px',
    marginRight: '1.5rem',
    textDecoration: 'none',
    color: '#fff',
    background: '#296C45'
  },
  activeImageLink: {
    stroke: '#fff'
  }
})

export default navBarStyle