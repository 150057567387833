import React from 'react';
import CustomButtons from '../../../atoms/CustomButtons/CustomButtons';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DatePicker } from "@material-ui/pickers";
import { DialogActions, Grid, withStyles } from '@material-ui/core';
import selectMonthYearModalStyle from 'assets/jss/material-dashboard-pro-react/components/selectMonthYearModalStyle';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectYearModal = ({ open, handleClose, handleSubmit, selectedYear, selectedMonth, handleYearChange, handleMonthChange, ...rest }) => {
  const { classes } = rest;

  return (
    <div>
      <Dialog className={classes.root} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Select Month and Year
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={6}>
            <DatePicker
              className={classes.picker}
              autoOk
              disableToolbar
              disableFuture
              format="MMMM"
              variant="static"
              views={["month"]}
              value={selectedMonth}
              onChange={handleMonthChange}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              className={classes.picker}
              autoOk
              disableToolbar
              disableFuture
              format="YYYY"
              variant="static"
              views={["year"]}
              minDate="2022-01-01"
              maxDate="2022-12-31"
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <CustomButtons onClick={handleClose} secondary fullWidth>Cancel</CustomButtons>
          <CustomButtons onClick={handleSubmit} primary fullWidth className={classes.button2}>Submit</CustomButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(selectMonthYearModalStyle)(SelectYearModal)