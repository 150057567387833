import { LoginActionTypes } from '../constants/index';

const initialState = {
  loading: false,
  verifyDetails: null,
  loggedInUserDetails: null,
  isLoggedIn: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LoginActionTypes.post_sendOTP.REQUEST:
      return {
        ...state,
        loading: true,
        verifyDetails: null,
        isLoggedIn: false
      };
    case LoginActionTypes.post_sendOTP.SUCCESS:
      return {
        ...state,
        loading: false,
        verifyDetails: payload,
        isLoggedIn: true
      };
    case LoginActionTypes.post_sendOTP.FAILURE:
      return {
        ...state,
        loading: false,
        verifyDetails: payload,
        isLoggedIn: false
      };
    case LoginActionTypes.post_verifyOTP.REQUEST:
      return {
        ...state,
        loading: true,
        loggedInUserDetails: null,
        isLoggedIn: false
      };
    case LoginActionTypes.post_verifyOTP.SUCCESS:
      return {
        ...state,
        loading: false,
        loggedInUserDetails: payload,
        isLoggedIn: true
      };
    case LoginActionTypes.post_verifyOTP.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false
      };
    case LoginActionTypes.get_refreshToken.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false
      }
    case LoginActionTypes.get_refreshToken.SUCCESS:
      return {
        ...state,
        loading: false,
        loggedInUserDetails: payload,
        isLoggedIn: true
      }
    case LoginActionTypes.get_refreshToken.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false
      }
    default:
      return state;
  }
};
