const personalDetailsStyle = theme => ({
  card: {
    padding: '20px',
    marginTop: '1rem',
    border: 'none',
    background: '#F7F7F7',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.15)',
  },
  topContainer: {
    padding: '20px'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  labelText: {
    color: '#000',
    opacity: 0.8,
    marginTop: '1rem'
  },
  labelTextBold: {
    color: '#000',
    opacity: 0.8,
    marginTop: '1rem',
    fontSize: '1rem',
    fontWeight: 600
  },
  valueText: {
    color: '#000',
    opacity: 0.6
  },
  image: {
    cursor: 'pointer'
  },
  autoMargin: {
    margin: 'auto'
  }
})

export default personalDetailsStyle;