import React from "react";
import Grid from '@material-ui/core/Grid';
import { Typography, withStyles } from "@material-ui/core";
import BackArrow from '../../assets/icons/BackArrow.svg';
import Card from '../../atoms/Card/Card';
import raisedQueryStyle from "assets/jss/material-dashboard-pro-react/pages/raisedQueryStyle";

const RaisedQuery = ({ ...props }) => {
  const { history, classes } = props;

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.subContainer}>
          <img src={BackArrow} onClick={() => history.goBack()} />
          <Typography className={classes.autoMargin} variant="h6">Raised Query</Typography>
        </div>
        <Grid container >
          <Grid item xs={12}>
            <Card className={classes.card}>
              <div className={classes.container}>
                <Typography className={classes.title}>Payslip</Typography>
                <div className={classes.cardContent}><Typography className={classes.cardText}>Resolved</Typography></div>
              </div>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.labelText}>Reason</Typography>
                  <Typography className={classes.valueText}>Dummy Text</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.labelText}>Date</Typography>
                  <Typography className={classes.valueText}>14/12/21</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.labelText}>Description</Typography>
                  <Typography className={classes.valueText}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withStyles(raisedQueryStyle)(RaisedQuery);
