import { PayslipActionTypes } from '../constants/index';

const initialState = {
  loading: false,
  payslipData: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PayslipActionTypes.get_PayslipDetailsByDate.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PayslipActionTypes.get_PayslipDetailsByDate.SUCCESS:
      return {
        ...state,
        loading: false,
        payslipData: payload,
      };
    case PayslipActionTypes.get_PayslipDetailsByDate.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
