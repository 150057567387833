const allowanceStyle = theme => ({
  container: {
    background: '#F0F5F2',
    border: "1px solid #3CC1B1"
  },
  subContainer: {
    background: '#fff',
    padding: '10px',
    borderRadius: '8px',
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '40px 10px'
  },
  text: {
    fontSize: '14px'
  },
})

export default allowanceStyle;