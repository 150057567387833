const appBarStyle = theme => ({
  appBar: {
    background: 'white',
    boxShadow: 'none',
    width: '100%',
    height: '80px',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  topContainer: {
    display: 'flex',
    width: '100%',
    padding: '15px'
  },
  subContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: "center"
  },
  iconContainer: {
    padding: '10px',
    border: '0.7px solid #296C45',
    marginRight: '1rem',
    borderRadius: '4px',
    background: '#F0F5F2'
  },
  avatar: {
    height: '50px',
    width: '50px'
  },
  logo: {
    cursor: 'pointer'
  }
})

export default appBarStyle