import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, withStyles } from "@material-ui/core";
import CustomButtons from '../../atoms/CustomButtons/CustomButtons';
import AppraisalDownload from '../../assets/images/vectors/AppraisalDownload.svg';
import Calender from '../../assets/images/vectors/Calender.svg';
import SelectYearModal from './Modal/SelectYearModal';
import DownloadIcon from '../../assets/icons/Download.svg';
import appraisalStyle from "assets/jss/material-dashboard-pro-react/pages/appraisalStyle";
import { GetAppraisalDetailsAction } from '../UserProfile/actions/UserProfileActions';
import NoData from '../../assets/images/vectors/NoData.svg';

class Appraisal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedYear: ''
    }
  }

  handleSubmit = () => {
    let postData = {
      "paydate": '2022'
    }
    this.props.GetAppraisalDetailsAction(postData)
    this.handleClose()
  }

  handleDateChange = () => {
    this.setState({
      selectedYear: '2022'
    })
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      selectedYear: '2022'
    })
  };

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  render() {
    const { classes, appraisalDetails } = this.props;
    const { open, selectedYear } = this.state;

    return (
      <>
        {open &&
          <SelectYearModal
            open={open}
            handleClose={this.handleClose}
            selectedYear={selectedYear}
            handleDateChange={this.handleDateChange}
            handleSubmit={this.handleSubmit}
          />
        }
        <div className={classes.topContainer}>
          {
            selectedYear === '' ?
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.container}>
                    <img src={Calender} height="130" width="160" className={classes.autoMargin} />
                    <Typography className={classes.text}>To access your Appraisal data, kindly select the year below.</Typography>
                    <CustomButtons onClick={this.handleClickOpen} fullWidth primary className={classes.autoMargin}>Select Year</CustomButtons>
                  </div>
                </Grid>
              </Grid> :
              appraisalDetails ?
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.container}>
                      <div className={classes.subContainer}>
                        <div className={classes.buttonContainer}>
                          <Typography className={classes.text2}>2022</Typography></div>
                        <CustomButtons onClick={this.handleClickOpen} primary>Select Year</CustomButtons>
                      </div>
                      <img src={AppraisalDownload} height="130" width="160" className={classes.autoMargin} />
                      <Typography className={classes.text}>To download your appraisal letter of 2022  as PDF, kindly download from below.</Typography>
                      <CustomButtons onClick={() => window.open(appraisalDetails.link)} fullWidth primary className={classes.autoMargin}><img src={DownloadIcon} className={classes.image} />Download Appraisal Letter</CustomButtons>
                    </div>
                  </Grid>
                </Grid>
                :
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.container}>
                      <Typography className={classes.text1}>No appraisal letter available!</Typography>
                      <img src={NoData} height="100%" width="100%" className={classes.autoMargin} />
                    </div>
                  </Grid>
                </Grid>
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    appraisalDetails: state.userProfileReducer.appraisalDoc !== null && state.userProfileReducer.appraisalDoc.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      GetAppraisalDetailsAction
    }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appraisalStyle)(Appraisal));
