import React, { Component } from "react";
import MySlider from '../../atoms/Slider/Slider';
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@material-ui/core/styles';
import {
  role
} from '../../utils/utility';
import { adminRoutes, initRoutes } from '../../routers/RolebasedRoutes/adminRoutes';
import PrivateRoute from '../../routers/PrivateRoutes/PrivateRoute';
import userLayoutStyle from "assets/jss/material-dashboard-pro-react/layouts/userLayoutStyle";
import AppBar from "molecules/AppBar/AppBar";
import NavBar from "molecules/NavBar/NavBar";
import { GetAllBannersAction } from '../../pages/UserProfile/actions/UserProfileActions';

class UserLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerData: []
    }
  }

  componentDidMount = () => {
    this.props.GetAllBannersAction()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.banners !== undefined && this.props.banners !== null && this.props.banners !== prevProps.banners) {
      this.setState({
        bannerData: this.props.banners
      })
    }
  }


  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.views) {
        return this.getRoutes(prop.views);
      }
      if (prop.subViews) {
        return this.getRoutes(prop.subViews)
      }
      if (prop.layout === "/User") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            user={role}
          />
        );
      }
      else {
        return null;
      }
    });
  };

  defineRole = (role) => {
    if (role.toUpperCase() === "USER") {
      return this.getRoutes(adminRoutes)
    } else {
      return;
    }
  }

  defineInitRoutes = (role) => {
    if (role.toUpperCase() === "USER") {
      return this.getRoutes(initRoutes)
    } else {
      return;
    }
  }

  drawerMenuItems = (role) => {
    if (role.toUpperCase() === "USER") {
      return adminRoutes;
    } else {
      return;
    }
  }

  renderSlider = () => {
    if (
      window.location.pathname === '/User/Profile' ||
      window.location.pathname === '/User/PersonalDetails' ||
      window.location.pathname === '/User/RaisedQuery' ||
      window.location.pathname === '/User/ProfessionalDetails' ||
      window.location.pathname === '/User/Settings' ||
      window.location.pathname === '/User/FAQ' ||
      window.location.pathname === '/User/BankAccountDetails' ||
      window.location.pathname === '/User/Allowance' ||
      window.location.pathname === '/User/Attendence' ||
      window.location.pathname === '/User/GrossSalary' ||
      window.location.pathname === '/User/Deduction'
    ) return

    return (
      <MySlider
        bannerData={this.state.bannerData}
      />
    )
  }

  renderNavbar = () => {
    if (
      window.location.pathname === '/User/Profile' ||
      window.location.pathname === '/User/PersonalDetails' ||
      window.location.pathname === '/User/RaisedQuery' ||
      window.location.pathname === '/User/ProfessionalDetails' ||
      window.location.pathname === '/User/Settings' ||
      window.location.pathname === '/User/FAQ' ||
      window.location.pathname === '/User/BankAccountDetails' ||
      window.location.pathname === '/User/Allowance' ||
      window.location.pathname === '/User/Attendence' ||
      window.location.pathname === '/User/GrossSalary' ||
      window.location.pathname === '/User/Deduction'
    ) return

    return <NavBar
      routes={this.drawerMenuItems(role)} />
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <main className={classes.mainPanel}>
          <AppBar
            userDetails={this.props.userDetails}
          />
          {this.renderSlider()}
          <div className={classes.container}>
            {this.renderNavbar()}
          </div>
          <Switch>
            {this.defineRole(role)}
            {this.defineInitRoutes(role)}
          </Switch>
        </main>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails.data,
    banners: state.userProfileReducer.banners !== null && state.userProfileReducer.banners.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      GetAllBannersAction
    }, dispatch)
  };
}

const UserLayoutWrapped = withStyles(userLayoutStyle)(UserLayout);
export default connect(mapStateToProps, mapDispatchToProps)(UserLayoutWrapped);