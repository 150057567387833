/**
 @desc
 * Centralized unique action types for Buyer module.
 */


import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from '../../../utils/utility';

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const GET_USER_DETAILS_API = `${API_URL}/app/employee/details`;
export const GET_USER_BANK_DETAILS_API = `${API_URL}/app/employee/bankdetails`;
export const GET_ALL_BANNERS_API = `${API_URL}/app/banner/list`;
export const POST_DOWNLOAD_APPRAISAL_API = `${API_URL}/app/appraisal/detail`;

export const UserProfileActionTypes = {
  get_UserDetails: createRequestActionTypes('GET_USER_DETAILS'),
  get_UserBankDetails: createRequestActionTypes('GET_USER_BANK_DETAILS'),
  get_AllBanners: createRequestActionTypes('GET_ALL_BANNERS'),
  post_DownloadAppraisal: createRequestActionTypes('POST_DOWNLOAD_APPRAISAL'),
};