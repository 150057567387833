const userLayoutStyle = theme => ({
  mainPanel: {
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    flexGrow: 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  container: {
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    background: '#F0F5F2',
    minHeight: '100%'
  }
})

export default userLayoutStyle;