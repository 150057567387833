import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Card from "atoms/Card/Card";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Divider, Typography, withStyles } from "@material-ui/core";
import CustomAvatar from "atoms/CustomAvatar/CustomAvatar";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import ProfessionalDetailsIcon from '../../assets/icons/Profile/ProfessionalDetails.svg';
import PersonalDetailsIcon from '../../assets/icons/Profile/PersonalDetails.svg';
import ChevronRight from '../../assets/icons/Profile/ChevronRight.svg';
import StarIcon from '../../assets/icons/Profile/Star.svg';
import RewardsIcon from '../../assets/icons/Profile/RewardPoints.svg';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import userProfileStyle from "assets/jss/material-dashboard-pro-react/pages/userProfileStyle";
import { GetUserDetailsAction, GetUserBankDetailsAction } from './actions/UserProfileActions';
import CustomButtons from "atoms/CustomButtons/CustomButtons";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import FAQIcon from '../../assets/icons/Profile/FAQ.svg';
// import RaisedQueryIcon from '../../assets/icons/Profile/RaisedQuery.svg';
// import SettingsIcon from '../../assets/icons/Profile/Settings.svg';

const items = [
  {
    name: "Personal Details",
    path: "/User/PersonalDetails",
    icon: PersonalDetailsIcon
  },
  {
    name: "Professional Details",
    path: "/User/ProfessionalDetails",
    icon: ProfessionalDetailsIcon
  },
  // {
  //   name: "Raised Query",
  //   path: "/User/RaisedQuery",
  //   icon: RaisedQueryIcon
  // },
  // {
  //   name: "Settings",
  //   path: "/User/Settings",
  //   icon: SettingsIcon
  // },
  // {
  //   name: "Frequently Asked Questions",
  //   path: "/User/FAQ",
  //   icon: FAQIcon
  // }
]

class UserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      bankData: []
    }
  }

  componentDidMount = () => {
    this.props.GetUserDetailsAction()
    this.props.GetUserBankDetailsAction()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.userDetails !== undefined && this.props.userDetails !== null && this.props.userDetails !== prevProps.userDetails) {
      this.setState({
        data: this.props.userDetails
      })
    }
    if (this.props.userBankDetails !== undefined && this.props.userBankDetails !== null && this.props.userBankDetails !== prevProps.userBankDetails) {
      this.setState({
        bankData: this.props.userBankDetails
      })
    }
  }

  render() {
    const { history, classes } = this.props;
    const { data, bankData } = this.state;

    return (
      <>
        <div className={classes.topContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <div className={classes.subContainer}>
                  <CustomAvatar className={classes.avatar} userDetails={this.props.userDetails} />
                  <div className={classes.headerContainer}>
                    <Typography variant="subtitle2">{data.fullname}</Typography>
                    <div className={classes.subContainer}>
                      <img src={StarIcon} />
                      <Typography variant="subtitle2" className={classes.text}>N/A</Typography>
                    </div>
                    <div className={classes.subContainer}>
                      <img src={RewardsIcon} />
                      <Typography variant="subtitle2" className={classes.text}>N/A</Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.cardSection}>
                  <div className={classes.cardSubSection}>
                    <Typography variant="subtitle2">Mobile</Typography>
                    <Typography variant="subtitle2">Email</Typography>
                    <Typography variant="subtitle2">Employee ID</Typography>
                  </div>
                  <div className={classes.text}>
                    <Typography variant="subtitle2">:</Typography>
                    <Typography variant="subtitle2">:</Typography>
                    <Typography variant="subtitle2">:</Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" className={classes.text1}>{data.mobile}</Typography>
                    <Typography variant="subtitle2" className={classes.text1}>{data.email}</Typography>
                    <Typography variant="subtitle2" className={classes.text1}>{data.empcode}</Typography>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.container} onClick={() => history.push(`/User/BankAccountDetails`)}>
                <Typography variant="subtitle1" className={classes.title}>BANK ACCOUNT DETAILS</Typography>
                <div className={classes.titleContainer}>
                  <AccountBalanceIcon />
                  <div className={classes.textContainer}>
                    <Typography variant="subtitle2" className={classes.text2}>{bankData.bankname}</Typography>
                    <Typography variant="caption" className={classes.text3}>Savings Account</Typography>
                  </div>
                  <img src={ChevronRight} className={classes.text5} />
                </div>
              </div>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.bottomContainer}>
                {
                  items.map((item, key) => {
                    return (
                      <NavLink key={key} className={classes.link} to={item.path}>
                        <div className={classes.linkContainer}>
                          <img src={item.icon} />
                          <Typography className={classes.text4} variant="subtitle1">{item.name}</Typography>
                          <img src={ChevronRight} className={classes.text5} />
                        </div>
                      </NavLink>
                    )
                  })
                }
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <CustomButtons primary onClick={() => history.goBack()} className={classes.arrowBack}>
              <ArrowBackIcon className={classes.arrowBackIcon} />
              Go back to homepage
              </CustomButtons>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.userProfileReducer.userDetails !== null ? state.userProfileReducer.userDetails.data : '',
    userBankDetails: state.userProfileReducer.userBankDetails !== null ? state.userProfileReducer.userBankDetails.data : ''
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      GetUserDetailsAction,
      GetUserBankDetailsAction
    }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(userProfileStyle)(UserProfile)));
