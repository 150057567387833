
/**
 @desc
 * Centralized unique actions for User Profile Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  UserProfileActionTypes,
  GET_USER_DETAILS_API,
  GET_USER_BANK_DETAILS_API,
  GET_ALL_BANNERS_API,
  POST_DOWNLOAD_APPRAISAL_API
} from '../constants/index';


export const GetUserDetailsAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(UserProfileActionTypes.get_UserDetails.REQUEST));
    fetch(`${GET_USER_DETAILS_API}`, {
      method: 'POST',
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(actionCreator(UserProfileActionTypes.get_UserDetails.FAILURE, response));
          showErrorMessage(response.message)
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage('Session Timed Out', 3000);
          localStorage.clear();
          dispatch(actionCreator(UserProfileActionTypes.get_UserDetails.FAILURE, response));
          window.location.reload();
        } else {
          dispatch(actionCreator(UserProfileActionTypes.get_UserDetails.SUCCESS, response));
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UserProfileActionTypes.get_UserDetails.FAILURE));
        console.log('error get_UserDetails ..', error);
      });
  };
};

export const GetUserBankDetailsAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(UserProfileActionTypes.get_UserBankDetails.REQUEST));
    fetch(`${GET_USER_BANK_DETAILS_API}`, {
      method: 'POST',
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(actionCreator(UserProfileActionTypes.get_UserBankDetails.FAILURE, response));
          showErrorMessage(response.message)
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage('Session Timed Out', 3000);
          localStorage.clear();
          dispatch(actionCreator(UserProfileActionTypes.get_UserBankDetails.FAILURE, response));
          window.location.reload();
        } else {
          dispatch(actionCreator(UserProfileActionTypes.get_UserBankDetails.SUCCESS, response));
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UserProfileActionTypes.get_UserBankDetails.FAILURE));
        console.log('error get_UserBankDetails ..', error);
      });
  };
};

export const GetAllBannersAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(UserProfileActionTypes.get_AllBanners.REQUEST));
    fetch(`${GET_ALL_BANNERS_API}`, {
      method: 'GET',
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(actionCreator(UserProfileActionTypes.get_AllBanners.FAILURE, response));
          showErrorMessage(response.message)
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage('Session Timed Out', 3000);
          localStorage.clear();
          dispatch(actionCreator(UserProfileActionTypes.get_AllBanners.FAILURE, response));
          window.location.reload();
        } else {
          dispatch(actionCreator(UserProfileActionTypes.get_AllBanners.SUCCESS, response));
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UserProfileActionTypes.get_AllBanners.FAILURE));
        console.log('error get_AllBanners ..', error);
      });
  };
};

export const GetAppraisalDetailsAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(UserProfileActionTypes.post_DownloadAppraisal.REQUEST));
    fetch(`${POST_DOWNLOAD_APPRAISAL_API}`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(actionCreator(UserProfileActionTypes.post_DownloadAppraisal.FAILURE, response));
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage('Session Timed Out', 3000);
          localStorage.clear();
          dispatch(actionCreator(UserProfileActionTypes.post_DownloadAppraisal.FAILURE, response));
          window.location.reload();
        } else {
          dispatch(actionCreator(UserProfileActionTypes.post_DownloadAppraisal.SUCCESS, response));
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UserProfileActionTypes.post_DownloadAppraisal.FAILURE));
        console.log('error post_DownloadAppraisal ..', error);
      });
  };
};