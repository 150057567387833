import { UserProfileActionTypes } from '../constants/index';

const initialState = {
  loading: false,
  userDetails: null,
  userBankDetails: null,
  appraisalDoc: null,
  banners: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UserProfileActionTypes.get_UserDetails.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserProfileActionTypes.get_UserDetails.SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload,
      };
    case UserProfileActionTypes.get_UserDetails.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UserProfileActionTypes.get_UserBankDetails.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserProfileActionTypes.get_UserBankDetails.SUCCESS:
      return {
        ...state,
        loading: false,
        userBankDetails: payload,
      };
    case UserProfileActionTypes.get_UserBankDetails.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UserProfileActionTypes.get_AllBanners.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserProfileActionTypes.get_AllBanners.SUCCESS:
      return {
        ...state,
        loading: false,
        banners: payload,
      };
    case UserProfileActionTypes.get_AllBanners.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UserProfileActionTypes.post_DownloadAppraisal.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserProfileActionTypes.post_DownloadAppraisal.SUCCESS:
      return {
        ...state,
        loading: false,
        appraisalDoc: payload,
      };
    case UserProfileActionTypes.post_DownloadAppraisal.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
