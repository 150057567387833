import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { Typography, withStyles } from "@material-ui/core";
import BackArrow from '../../assets/icons/BackArrow.svg';
import Card from '../../atoms/Card/Card';
import professionalDetailsStyle from "assets/jss/material-dashboard-pro-react/pages/professionalDetailsStyle";

const ProfessionalDetails = ({ ...props }) => {
  const { history, classes, loggedInUserDetails } = props;

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.subContainer}>
          <img src={BackArrow} className={classes.image} onClick={() => history.goBack()} />
          <Typography className={classes.autoMargin} variant="h6">Professional Details</Typography>
        </div>
        <Card className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Designation</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.designation}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Reporting Manager</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.manager}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Department</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.department}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Employee Code</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.empcode}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">DOJ</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{moment(loggedInUserDetails.doj).format('DD-MM-YYYY')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Grade</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.grade}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Branch</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.branch}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Division</Typography>
              <Typography className={classes.valueText} variant="subtitle1">{loggedInUserDetails.division}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Rating</Typography>
              <Typography className={classes.valueText} variant="subtitle1">N/A</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.labelTextBold} variant="subtitle1">Reward Points</Typography>
              <Typography className={classes.valueText} variant="subtitle1">N/A</Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails.data,
  };
}

export default connect(mapStateToProps)(withStyles(professionalDetailsStyle)(ProfessionalDetails));

