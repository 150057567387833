import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  withStyles
} from "@material-ui/core";
import cardStyle from '../../assets/jss/material-dashboard-pro-react/components/cardStyle';

const Card = ({ ...props }) => {
  const { classes } = props;
  const { children, className, ...rest } = props;

  const cardClasses = classNames({
    [classes.card]: true,
    [className]: className !== undefined,
  });

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default withStyles(cardStyle)(Card);
