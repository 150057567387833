import React from 'react';
import CustomButtons from '../../../atoms/CustomButtons/CustomButtons';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DatePicker } from "@material-ui/pickers";
import { DialogActions, withStyles } from '@material-ui/core';
import appraisalModalStyle from 'assets/jss/material-dashboard-pro-react/components/appraisalModalStyle';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectYearModal = ({ open, handleClose, selectedYear, handleSubmit, handleDateChange, ...rest }) => {
  const { classes } = rest;

  return (
    <div>
      <Dialog className={classes.root} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Select Year
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.dateContainer}>
          <DatePicker
            autoOk
            disableToolbar
            disablePast
            disableFuture
            variant="static"
            views={["year"]}
            minDate="2022-01-01"
            maxDate="2022-12-31"
            value={selectedYear}
            onChange={handleDateChange}
          />
        </div>
        <DialogActions>
          <CustomButtons onClick={handleClose} fullWidth secondary>Cancel</CustomButtons>
          <CustomButtons onClick={handleSubmit} fullWidth primary className={classes.button2}>Submit</CustomButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(appraisalModalStyle)(SelectYearModal)