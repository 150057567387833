import React from "react";
import Login from '../../pages/Login/Login';


export default function AuthLayout() {
  return (
    <>
      <Login />
    </>
  );
};
