//Admin
import Appraisal from '../../pages/Appraisal/Appraisal';
import Payslip from 'pages/Payslip/Payslip';

//profile
import UserProfile from '../../pages/UserProfile/UserProfile';
import PersonalDetails from '../../pages/PersonalDetails/PersonalDetails';
import Settings from '../../pages/Settings/Settings';
import RaisedQuery from '../../pages/RaisedQuery/RaisedQuery';
import FAQ from '../../pages/FAQ/FAQ';
import ProfessionalDetails from '../../pages/ProfessionalDetails/ProfessionalDetails';
import BankAccountDetails from 'pages/BankAccountDetails/BankAccountDetails';

//icons
import AppraisalIcon from '../../assets/icons/Appraisal.svg';
import PayslipIcon from '../../assets/icons/Payslip.svg';
// import CalenderIcon from '../../assets/icons/Attendence.svg';
// import EPFIcon from '../../assets/icons/EPF.svg';

//active icons
import ActiveAppraisalIcon from '../../assets/icons/ActiveAppraisal.svg';
import ActivePayslipIcon from '../../assets/icons/ActivePayslip.svg';
// import ActiveCalenderIcon from '../../assets/icons/ActiveAttendance.svg';
// import ActiveEPFIcon from '../../assets/icons/ActiveEPF.svg';
import GrossSalary from 'pages/GrossSalary/GrossSalary';
import Allowance from 'pages/Allowance/Allowance';
import Deduction from 'pages/Deduction/Deduction';
import Attendence from 'pages/Attendence/Attendence';


export const initRoutes = [
  {
    path: '/Profile',
    name: "User Profile",
    component: UserProfile,
    layout: "/User"
  },
  {
    path: '/PersonalDetails',
    name: "Personal Details",
    component: PersonalDetails,
    layout: "/User"
  },
  {
    path: '/ProfessionalDetails',
    name: "Professional Details",
    component: ProfessionalDetails,
    layout: "/User"
  },
  {
    path: '/Settings',
    name: "Settings",
    component: Settings,
    layout: "/User"
  },
  {
    path: '/RaisedQuery',
    name: "Raised Query",
    component: RaisedQuery,
    layout: "/User"
  },
  {
    path: '/FAQ',
    name: "Frequently Asked Questions",
    component: FAQ,
    layout: "/User"
  },
  {
    path: '/BankAccountDetails',
    name: "Bank Account Details",
    component: BankAccountDetails,
    layout: "/User"
  },

  {
    path: '/GrossSalary',
    name: "Gross Salary",
    component: GrossSalary,
    layout: "/User"
  },
  {
    path: '/Allowance',
    name: "Allowance",
    component: Allowance,
    layout: "/User"
  },
  {
    path: '/Deduction',
    name: "Deduction",
    component: Deduction,
    layout: "/User"
  },
  {
    path: '/Attendence',
    name: "Attendence",
    component: Attendence,
    layout: "/User"
  },
]

export const adminRoutes = [
  {
    path: "/Payslip",
    name: "Payslip",
    icon: PayslipIcon,
    activeIcon: ActivePayslipIcon,
    layout: "/User",
    component: Payslip
  },
  {
    path: "/Appraisal",
    name: "Appraisal",
    icon: AppraisalIcon,
    activeIcon: ActiveAppraisalIcon,
    layout: "/User",
    component: Appraisal
  },
  // {
  //   path: "/Attendence",
  //   name: "Attendence",
  //   icon: CalenderIcon,
  //   activeIcon: ActiveCalenderIcon,
  //   layout: "/User",
  //   component: Dashboard
  // },
  // {
  //   path: "/EPF",
  //   name: "EPF",
  //   icon: EPFIcon,
  //   activeIcon: ActiveEPFIcon,
  //   layout: "/User",
  //   component: Dashboard
  // },
]
