const appraisalStyle = theme => ({
  topContainer: {
    padding: '20px',
    background: '#F0F5F2'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: '15px 20px'
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonContainer: {
    padding: '4px 14px',
    background: '#F0F5F2',
    border: '1px solid #296C45',
    borderRadius: '24px'
  },
  autoMargin: {
    margin: 'auto'
  },
  text: {
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center'
  },
  text1: {
    fontSize: '18px',
    textAlign: 'center'
  },
  text2: {
    color: '#296C45',
    fontSize: '14px'
  },
  image: {
    marginRight: '0.5rem'
  }
})

export default appraisalStyle;