import React, { Component } from 'react';
import { withStyles, Typography, Grid, TextField } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomButtons from "atoms/CustomButtons/CustomButtons";
import WellnessLogo from '../../assets/images/logo.png';
import authLayoutStyle from 'assets/jss/material-dashboard-pro-react/layouts/authLayoutStyle';
import { VerifyOTPAction, SendOTPAction } from './actions/LoginActions';
import { phoneRegExp } from '../../utils/utility';
import IndiaIcon from '../../assets/images/india.svg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: false,
      OneTimePassword: '',
      mobileNumber: '',
      seconds: 120,
      error: false
    };
  }

  onFormSubmitHandler = () => {
    let postData = {
      'mobile': this.state.mobileNumber,
      'otp': this.state.OneTimePassword
    }

    this.props.VerifyOTPAction(postData)
  }

  handleSendOTP = () => {
    let postData = {
      'mobile': this.state.mobileNumber
    }

    this.props.SendOTPAction(postData);
    this.setState({
      page: true,
      seconds: 120
    })
  }

  handleSetOneTimePassword = (value) => {
    this.setState({
      OneTimePassword: value
    })
  }

  handleSetMobileNumber = (e) => {
    const { value } = e.target;

    if (value.length === 10 && !phoneRegExp.test(value)) {
      this.setState({
        error: true,
        mobileNumber: value
      })
    } else if (value.length !== 10) {
      this.setState({
        error: false,
        mobileNumber: value
      })
    } else {
      this.setState({
        mobileNumber: value
      })
    }
  }

  countdownTimer = () => {
    const { seconds } = this.state;
    seconds > 0 && setTimeout(() =>
      this.setState({
        seconds: seconds - 1
      })
      , 1000);
  }

  handleChangePage = () => {
    this.setState({
      page: true
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.page && this.countdownTimer()}
        <div className={classes.mainContainer}>
          <div className={classes.logoContainer}>
            <img
              width="208px"
              height="88px"
              alt="Logo"
              src={WellnessLogo} />
          </div>
          <div className={classes.content}>
            <div style={{ height: '100%' }}>
              {this.props.status?.toUpperCase() !== "FAILURE" && this.state.page ?
                <Grid container justify="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.headers}>OTP</Typography>
                    <div style={{ padding: '5px 0' }}>
                      <Typography className={classes.subHeaders}>
                        Enter the OTP sent to your mobile number
                    </Typography>
                      <OtpInput
                        shouldAutoFocus
                        onChange={this.handleSetOneTimePassword}
                        value={this.state.OneTimePassword}
                        focusStyle={{ background: '#296C45', border: '2px solid #FFF138', color: '#fff' }}
                        inputStyle={{ height: '50px', width: '50px' }}
                        containerStyle={{ display: 'flex', justifyContent: 'space-evenly', margin: '20px 0' }}
                        numInputs={6}
                        separator={false}
                      />
                    </div>
                    <div className={classes.timer}>
                      {this.state.seconds > 0 && <Typography style={{ fontSize: '34px', color: '#FFF138' }}>{this.state.seconds}s</Typography>}
                    </div>
                    <div className={classes.timer}>
                      {this.state.seconds <= 0 && <Typography style={{ color: '#fff', fontSize: '16px', marginBottom: '30px' }}>
                        Didn't recieve any code? <a style={{ textDecoration: 'underline' }} onClick={this.handleSendOTP}>Resend</a>
                      </Typography>}
                    </div>
                    <div>
                      <CustomButtons
                        className={classes.root1}
                        disabled={this.state.OneTimePassword.length === 6 ? false : true}
                        onClick={this.onFormSubmitHandler}
                        fullWidth
                        primary>
                        Submit
                        </CustomButtons>
                    </div>
                  </Grid>
                </Grid>
                :
                <Grid container justify="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.headers}>Welcome</Typography>
                    <div style={{ padding: '5px 0' }}>
                      <Typography className={classes.subHeaders}>
                        Enter Registered Mobile Number
                    </Typography>
                      <TextField
                        className={classes.root}
                        placeholder="Enter Mobile Number"
                        error={this.state.error ? true : false}
                        onChange={(e) => this.handleSetMobileNumber(e)}
                        variant="outlined"
                        helperText={this.state.error ? "Invalid Number" : ''}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">
                            <div className={classes.adormentContainer}>
                              <img height="20" width="30" src={IndiaIcon} />
                              <Typography className={classes.labelText}>+91</Typography>
                            </div>
                          </InputAdornment>
                        }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </div>
                    <div>
                      <CustomButtons
                        fullWidth
                        primary
                        className={classes.root1}
                        onClick={this.handleSendOTP}
                        disabled={this.state.mobileNumber.length < 10 || this.state.error ? true : false}
                      >GET OTP
                      <ArrowRightAltIcon style={{ marginLeft: '0.5rem' }} />
                      </CustomButtons>
                    </div>
                  </Grid>
                </Grid>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    status: state.loginReducer.verifyDetails !== null ? state.loginReducer.verifyDetails?.status : '',
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      SendOTPAction,
      VerifyOTPAction
    }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(authLayoutStyle)(Login));