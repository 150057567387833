import React from 'react';
import { withRouter } from 'react-router';
import {
  AppBar,
  Toolbar,
  withStyles
} from "@material-ui/core";
import WellnessLogo from '../../assets/images/logo.png';
// import NotificationsIcon from '../../assets/icons/Notifications.svg';
import PhoneIcon from '../../assets/icons/PhoneIcon.svg';
import CustomAvatar from '../../atoms/CustomAvatar/CustomAvatar';
import PopperLinks from 'atoms/PopperLinks/PopperLinks';
import appBarStyle from '../../assets/jss/material-dashboard-pro-react/components/appBarStyle';

const CustomAppBar = ({ ...props }) => {
  const [openProfile, setOpenProfile] = React.useState(null);
  const { classes, history, userDetails } = props;

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleRedirect = () => {
    history.push(`/User/Payslip`)
  }


  return (
    <AppBar
      position="sticky"
      className={classes.appBar}
    >
      <Toolbar disableGutters>
        <div className={classes.topContainer}>
          <img src={WellnessLogo} className={classes.logo} alt="Logo" height="56px" width="131px" onClick={handleRedirect} />
          <div className={classes.subContainer}>
            {/* <div className={classes.iconContainer}>
              <img src={NotificationsIcon} />
            </div> */}
            <div className={classes.iconContainer}>
            <a href="tel:(022)690337000"><img src={PhoneIcon} /> </a>
            </div>
            <CustomAvatar
              className={classes.avatar}
              onClick={handleClickProfile}
              userDetails={userDetails}
            />
            <PopperLinks
              userDetails={userDetails}
              openProfile={openProfile}
              handleClose={handleCloseProfile}
            />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(withStyles(appBarStyle)(CustomAppBar))