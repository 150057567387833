
/**
 @desc
 * Centralized unique actions for Login Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  LoginActionTypes,
  POST_SEND_OTP_API,
  POST_VERIFY_OTP_API,
  GET_REFRESH_TOKEN_API
} from '../constants/index';
import { handleLoginRedirect, showSuccessMessage, TOKEN_EXPIRY_MESSAGE, showErrorMessage } from '../../../utils/utility';


/**
* @param  {[object]} postData [The data needed as a payload for the API interaction]
*/
export const SendOTPAction = (postData) => {
  return dispatch => {
    dispatch(actionCreator(LoginActionTypes.post_sendOTP.REQUEST));
    fetch(`${POST_SEND_OTP_API}`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: jsonApiHeader(),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(actionCreator(LoginActionTypes.post_sendOTP.FAILURE, response));
          showErrorMessage(response.message)
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage('Session Timed Out', 3000);
          localStorage.clear();
          dispatch(actionCreator(LoginActionTypes.post_sendOTP.FAILURE, response));
          window.location.reload();
        } else {
          showSuccessMessage('OTP sent successfully')
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(LoginActionTypes.post_sendOTP.FAILURE));
        console.log('error post_sendOTP ..', error);
      });
  };
};

export const VerifyOTPAction = (postData) => {
  return dispatch => {
    dispatch(actionCreator(LoginActionTypes.post_verifyOTP.REQUEST));
    fetch(`${POST_VERIFY_OTP_API}`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: jsonApiHeader(),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          showErrorMessage(response.message)
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage('Session Timed Out', 3000);
          localStorage.clear();
          dispatch(actionCreator(LoginActionTypes.post_verifyOTP.FAILURE, response));
          window.location.reload();
        } else {
          handleLoginRedirect(response, '/User/Payslip');
          setTimeout(() => {
            dispatch(actionCreator(LoginActionTypes.post_verifyOTP.SUCCESS, response));
          }, 100);
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(LoginActionTypes.post_verifyOTP.FAILURE));
        console.log('error post_verifyOTP ..', error);
      });
  };
};

export const RefreshTokenAction = (requestToken) => {
  return dispatch => {
    dispatch(actionCreator(LoginActionTypes.get_refreshToken.REQUEST));
    fetch(`${GET_REFRESH_TOKEN_API}`, {
      method: 'GET',
      body: JSON.stringify(),
      headers: jsonApiHeader(requestToken),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(actionCreator(LoginActionTypes.get_refreshToken.FAILURE, response));
        } else if (response.status.toUpperCase() === "EXPIRED") {
          if (localStorage.getItem('LoggedOut') && localStorage.getItem('LoggedOut') === "true") {
            showSuccessMessage('Logged out successfully');
            localStorage.clear();
            dispatch(actionCreator(LoginActionTypes.get_refreshToken.FAILURE, response));
          } else {
            showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000)
            localStorage.clear();
            dispatch(actionCreator(LoginActionTypes.get_refreshToken.FAILURE, response));
          }
        } else {
          dispatch(actionCreator(LoginActionTypes.get_refreshToken.SUCCESS, response));
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(LoginActionTypes.get_refreshToken.FAILURE));
      });
  };
};


