const authLayoutStyle = {
  root: {
    background: '#EDEDED',
    margin: '20px 0',
    '& .MuiInputBase-root': {
      color: '#000000'
    },
    '& .MuiFormHelperText-root': {
      padding: '0.5rem 0 0 0',
      margin: 0,
      color: '#f44336',
      background: '#296C45'
    }
  },
  root1: {
    '&.Mui-disabled': {
      color: '#fff',
      background: 'grey'
    }
  },
  mainContainer: {
    height: '100vh'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '30%',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: '60px',
    borderTopRightRadius: '60px',
    background: '#296C45',
    height: '70%'
  },
  headers: {
    color: '#fff',
    fontSize: '36px',
    marginTop: '40px'
  },
  subHeaders: {
    color: '#fff',
    fontSize: '16px',
    marginTop: '-0.5rem'
  },
  timer: {
    display: 'flex',
    justifyContent: 'center'
  },
  adormentContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  labelText: {
    marginLeft: '0.5rem',
    color: '#000',
    opacity: 0.8
  }
};

export default authLayoutStyle;
