const payslipStyle = (theme) => ({
  root: {
    height: "48px",
    borderBottom: "1px solid #ccc",
    "& .MuiTabs-flexContainer": {
      "& .MuiTab-root": {
        minHeight: "48px",
        minWidth: "80px",
        textTransform: "capitalize",
      },
      "& .MuiButtonBase-root.Mui-selected": {
        color: "#296C45",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: `15.5px 14px !important`,
    },
  },
  topContainer: {
    padding: "20px",
    background: "#F0F5F2",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    padding: "15px 20px",
  },
  container: {
    padding: "5px",
    background: "#fff",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
  },
  yearMonthContainer: {
    padding: "4px 14px",
    background: "#F0F5F2",
    border: "1px solid #296C45",
    borderRadius: "24px",
  },
  yearMonthContainer1: {
    padding: "4px 14px",
    background: "#F0F5F2",
    border: "1px solid #296C45",
    borderRadius: "24px",
    marginLeft: "5px",
  },
  yearMonthText: {
    color: "#296C45",
    fontSize: "14px",
  },
  text: {
    marginTop: "1rem",
    marginBottom: "1rem",
    textAlign: "center",
  },
  text1: {
    fontSize: "16px",
    color: "#fff",
    marginLeft: "0.5rem",
  },
  text2: {
    marginRight: "1rem",
    color: "#fff",
  },
  text3: {
    color: "#fff",
  },
  link: {
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.5rem",
  },
  button1: {
    marginRight: "0.5rem",
  },
  button2: {
    margin: "auto",
    whiteSpace: "nowrap",
  },
  autoMargin: {
    margin: "auto",
  },
  grossSalaryContainer: {
    height: "60px",
    marginTop: "1rem",
    padding: "10px",
    borderRadius: "4px",
    background: "#539EE3",
    display: "flex",
    alignItems: "center",
  },
  allowanceContainer: {
    height: "60px",
    marginTop: "1rem",
    padding: "10px",
    borderRadius: "4px",
    background: "#3CA1C1",
    display: "flex",
    alignItems: "center",
  },
  deductionContainer: {
    height: "60px",
    marginTop: "1rem",
    padding: "10px",
    borderRadius: "4px",
    background: "rgba(211, 67, 67, 0.55)",
    display: "flex",
    alignItems: "center",
  },
  attendenceContainer: {
    height: "60px",
    marginTop: "1rem",
    padding: "10px",
    borderRadius: "4px",
    background: "#9A8EDF",
    display: "flex",
    alignItems: "center",
  },
  bottomContainer: {
    display: "flex",
    borderRadius: "4px",
    justifyContent: "space-between",
    background: "#296C45",
    padding: "20px",
    margin: "1.5rem 0",
  },
  section: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  // MuiOutlinedInput: {
  //   input: {
  //     padding: `15.5px 14px !important`,
  //   },
  // },
});

export default payslipStyle;
