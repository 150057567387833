const raiseQueryModalStyle = theme => ({
  root: {
    '& .MuiDialog-paperFullScreen': {
      top: '70%',
      maxWidth: '500px',
      margin: 'auto',
      borderTopLeftRadius: '30px',
      borderTopRightRadius: '30px'
    }
  },
  appBar: {
    position: 'relative',
    background: '#E04339'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    margin: '15px'
  },
  button2: {
    marginRight: '0.5rem',
  }
})

export default raiseQueryModalStyle;