import React from 'react';
import { withRouter } from 'react-router';
import {
  Divider,
  Typography,
  Grow,
  Popper,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import Card from "../Card/Card";
import styles from "../../assets/jss/material-dashboard-pro-react/components/PopperLinksStyle";
import { handleLogoutRedirect } from 'utils/utility';

const useStyles = makeStyles(styles);

const PopperLinks = (props) => {
  const classes = useStyles();
  const { history, userDetails } = props;

  const handleGoToUserProfile = () => {
    history.push(`/User/Profile`)
    props.handleClose()
  }

  return (
    <>
      <Popper
        open={Boolean(props.openProfile)}
        anchorEl={props.openProfile}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Card className={classes.card}>
              <div className={classes.headerContainer}>
                <div className={classes.headerSubContainer}>
                  <Typography style={{ color: '#000', cursor: 'pointer' }} variant="subtitle2" onClick={handleGoToUserProfile}>
                    View Profile
                  </Typography>
                  <Typography style={{ color: '#000', opacity: 0.6 }} variant="caption">
                    {userDetails.fullname}
                  </Typography>
                </div>
              </div>
              <Divider className={classes.divider} />
              <MenuItem className={classes.item} onClick={() => props.handleClose()}>
                <Typography onClick={handleLogoutRedirect} style={{ color: '#F80909' }} variant="subtitle2">Logout</Typography>
              </MenuItem>
            </Card>
          </Grow>
        )}
      </Popper>
    </>
  )
};

export default withRouter(PopperLinks);