import React, { Component } from "react";
import { withStyles } from '@material-ui/core';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';
import sliderStyle from '../../assets/jss/material-dashboard-pro-react/components/sliderStyle';

class MySlider extends Component {

  handleGoTo = link => {
    if (link) window.open((link));
    else return
  }

  render() {
    const { bannerData, classes } = this.props;

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      initialSlide: 0,
      lazyLoad: "progressive",
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className={classes.topContainer}>
        <Slider {...settings}>
          {bannerData.map((banner, index) => {
            return (
              banner.isactive === "Y" &&
              <div key={index} className={classes.container} >
                <a href={banner.href} target="_blank">
                  <img src={banner.image} width="95%" className={classes.image} />
                </a>
              </div>
            )
          })}
        </Slider>
      </div>
    );
  }
}

export default withStyles(sliderStyle)(MySlider);
