import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { DatePicker } from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PanToolIcon from "@material-ui/icons/PanTool";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Label,
  Cell,
  // Legend,
} from "recharts";
import { Box, Typography, withStyles } from "@material-ui/core";
import CustomButtons from "../../atoms/CustomButtons/CustomButtons";
import PayslipIcon from "../../assets/images/vectors/Payslip.svg";
import SelectMonthYearModal from "./Modal/SelectMonthYearModal";
import RaiseQueryModal from "./Modal/RaiseQueryModal";
import payslipStyle from "assets/jss/material-dashboard-pro-react/pages/payslipStyle";
// import AllowanceIcon from "../../assets/icons/Payslip/Allowance.svg";
import GrossSalaryIcon from "../../assets/icons/Payslip/Gross Salary.svg";
// import AttendenceIcon from "../../assets/icons/Payslip/Attendance.svg";
// import DeductionIcon from "../../assets/icons/Payslip/Deduction.svg";
import MyAccordion from "../../atoms/Accordion/Accordion";
import GrossSalary from "pages/GrossSalary/GrossSalary";
// import Allowance from "pages/Allowance/Allowance";
// import Deduction from "pages/Deduction/Deduction";
import Attendence from "pages/Attendence/Attendence";
import moment from "moment";
import { GetPayslipDataAction } from "./actions/PayslipActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import SuccessQueryModal from "./Modal/SuccessQueryModal";

// const data01 = [
//   { color: "#FFDE59", value: 70000, subtitle: "Net Take Home " },
//   { value: 12500 },
// ];
// const data02 = [{ value: 10 }, { value: 20 }];

const colors = ["#539EE3", "#3CC1B1", "rgba(211, 67, 67, 0.55)", "#AEA2FC"];
const itemColors = ["#FFDE59", "#FF6A67", "#7FD956", "#E74043"];

function ChartDetail({ value, days, bgcolor, subtitle }) {
  return (
    <div style={{ paddingLeft: "20px", marginBottom: "15px" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box style={{ height: "9px", width: "9px" }} bgcolor={bgcolor} />
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#111111",
            fontFamily: "Roboto",
            marginLeft: "8px",
          }}
        >
          {value < 31 ? value : `₹ ${value}`}
        </Typography>
      </Box>
      <Typography
        style={{
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "Roboto",
          color: "rgba(17, 17, 17, 0.6)",
          textDecoration: "underline",
        }}
      >
        {subtitle}
      </Typography>
    </div>
  );
}

function CustomLabel({ amount, ...props }) {
  const { cx, cy } = props.viewBox;
  return (
    <>
      <text
        x={cx}
        y={cy - 10}
        fill="#111111"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        fontFamily="Roboto"
      >
        <tspan alignmentBaseline="middle" fontSize="18px" fontWeight="700">
          {amount}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 10}
        fill="#111111"
        className="recharts-text recharts-label"
        textAnchor="middle"
        fontFamily="Roboto"
        dominantBaseline="central"
      >
        <tspan fontSize="14px" color="#11111199" opacity={0.6} fontWeight="500">
          Total Earning
        </tspan>
      </text>
    </>
  );
}

class Payslip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: "2022",
      selectedMonth: (new Date().getMonth() + 1).toString(),
      open: false,
      openQuery: false,
      value: 0,
      data: [],
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
      selectedMonth: ((newValue ?? 11) + 1)?.toString(),
    });
    const postData = {
      paymonth: `${((newValue ?? 11) + 1)?.toString()}/${
        this.state.selectedYear
      }`,
    };
    this.props.GetPayslipDataAction(postData);
  };

  handleMonthChange = (value) => {
    this.setState({
      monthSelected: true,
      selectedMonth: moment(value).format("M"),
    });
  };

  handleYearChange = (value) => {
    this.setState({
      selectedYear: moment(value).format("YYYY"),
    });
  };

  handleInnerYearChange = (value) => {
    let postData = {
      paymonth: `${this.state.selectedMonth}/${moment(value).format("YYYY")}`,
    };

    this.props.GetPayslipDataAction(postData);

    this.setState({
      selectedYear: moment(value).format("YYYY"),
    });
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOpenQuery = () => {
    this.setState({
      openQuery: true,
    });
  };

  handleCloseQuery = () => {
    this.setState({
      openQuery: false,
    });
  };

  handleSubmit = () => {
    const postData = {
      paymonth: `${this.state.selectedMonth}/${this.state.selectedYear}`,
    };

    this.props.GetPayslipDataAction(postData);
    this.setState({
      open: false,
      monthSelected: true,
    });
  };

  render() {
    const { classes, payslips } = this.props;
    const { selectedYear, selectedMonth, open, openQuery } = this.state;
    const data = {
      data01: [
        {
          color: "#FF6A67",
          value: Number(
            payslips
              .find(({ key }) => key === "current_pay")
              ?.value?.find(
                ({ key }) => key === "day_absent" || key === "days_absent"
              )?.value ?? 0
          ),
          subtitle: "Days Unpaid",
        },
        {
          color: "#7FD956",
          value: Number(
            payslips
              .find(({ key }) => key === "current_pay")
              ?.value?.find(
                ({ key }) => key === "day_present" || key === "days_present"
              )?.value ?? 0
          ),
          subtitle: "Days Paid",
        },
      ],
      data02: [
        {
          color: "#E74043",
          value: Number(
            payslips
              .find(({ key }) => key === "current_pay")
              ?.value?.find(({ key }) => key === "total_deduction")?.value || 0
          ),
          subtitle: "Deductions",
        },
        {
          color: "#FFDE59",
          value: Number(
            payslips
              .find(({ key }) => key === "current_pay")
              ?.value?.find(({ key }) => key === "net_paid")?.value || 0
          ),
          subtitle: "Net Take Home",
        },
      ],
    };

    return (
      <>
        {open && (
          <SelectMonthYearModal
            open={open}
            handleClose={this.handleClose}
            handleSubmit={this.handleSubmit}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            handleMonthChange={this.handleMonthChange}
            handleYearChange={this.handleYearChange}
          />
        )}
        {openQuery && (
          <RaiseQueryModal
            open={openQuery}
            handleClose={this.handleCloseQuery}
          />
        )}
        <div className={classes.topContainer}>
          {selectedYear === "" || !this.state.monthSelected ? (
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.content}>
                  <img
                    src={PayslipIcon}
                    alt="payslip"
                    height="130"
                    width="160"
                    className={classes.autoMargin}
                  />
                  <Typography className={classes.text}>
                    To access your Payslip data, kindly select the month and
                    year below.
                  </Typography>
                  <CustomButtons
                    onClick={this.handleClickOpen}
                    fullWidth
                    primary
                    className={classes.autoMargin}
                  >
                    Select Month and Year
                  </CustomButtons>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} spacing={5}>
                <div className={classes.container}>
                  <div className={classes.subContainer}>
                    <div className={classes.buttonContainer}>
                      <div style={{ position: "relative" }}>
                        <ExpandMoreIcon
                          style={{
                            position: "absolute",
                            left: "100px",
                            top: "15px",
                            zIndex: 0,
                          }}
                        />
                        <DatePicker
                          autoOk
                          disableToolbar
                          disableFuture
                          format="YYYY"
                          style={{
                            width: "80%",
                            marginLeft: "7%",
                          }}
                          views={["year"]}
                          inputVariant="outlined"
                          variant="inline"
                          className="datepicker-style"
                          value={this.state.selectedYear}
                          onChange={this.handleInnerYearChange}
                        />
                      </div>

                      {/* <CustomButtons
                        danger
                        onClick={this.handleOpenQuery}
                        className={classes.button2}
                      >
                        <span style={{ marginRight: "10px" }}>
                          <PanToolIcon />
                        </span>
                        Raise Query
                      </CustomButtons> */}
                    </div>
                    <div className={classes.container}>
                      <Tabs
                        className={classes.root}
                        value={Number(this.state.selectedMonth || "1") - 1}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab label="Jan" value={0} />
                        <Tab label="Feb" value={1} />
                        <Tab label="Mar" value={2} />
                        <Tab label="Apr" value={3} />
                        <Tab label="May" value={4} />
                        <Tab label="Jun" value={5} />
                        <Tab label="Jul" value={6} />
                        <Tab label="Aug" value={7} />
                        <Tab label="Sep" value={8} />
                        <Tab label="Oct" value={9} />
                        <Tab label="Nov" value={10} />
                        <Tab label="Dec" value={11} />
                      </Tabs>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#111111CC",
                        }}
                      >
                        Your net pay for month
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        paddingTop: "30px",
                        alignItems: "center",
                      }}
                    >
                      <ResponsiveContainer height={200} width="60%">
                        <PieChart>
                          {/* inner */}
                          <Pie
                            data={data.data01}
                            dataKey="value"
                            cx={85}
                            cy={85}
                            innerRadius={50}
                            outerRadius={60}
                            labelLine={false}
                            blendStroke
                            startAngle={-270}
                            endAngle={90}
                            paddingAngle={-5}
                            cornerRadius={5}
                          >
                            <Label
                              value="Available"
                              position="center"
                              fontSize="14px"
                              fill="#fff"
                              opacity={0.6}
                              content={
                                <CustomLabel
                                  value1="Available"
                                  value2="14/23"
                                  amount={new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  }).format(
                                    Number(
                                      payslips
                                        .find(
                                          ({ key }) => key === "current_pay"
                                        )
                                        ?.value?.find(
                                          ({ key }) => key === "total_earning"
                                        )?.value
                                    )
                                  )}
                                />
                              }
                            />
                            <Cell fill={"#FF6A67"} />
                            <Cell fill={"#7FD956"} />
                          </Pie>

                          {/* outer */}
                          <Pie
                            data={data.data02}
                            dataKey="value"
                            cx={85}
                            cy={85}
                            innerRadius={75}
                            outerRadius={90}
                            startAngle={-270}
                            endAngle={90}
                            labelLine={false}
                            blendStroke
                            paddingAngle={-5}
                            cornerRadius={10}
                          >
                            <Cell fill={"#C42031"} />
                            <Cell fill={"#FFDE59"} />
                          </Pie>

                          {/* <Legend
                            align="right"
                            verticalAlign="middle"
                            layout="vertical"
                          /> */}
                        </PieChart>
                      </ResponsiveContainer>
                      <div>
                        {/* {data.data02
                          .slice(0)
                          .reverse()
                          .map((item, index) => {
                            return (
                              <ChartDetail
                                key={index}
                                bgcolor={item.color}
                                value={item.value}
                                days={item.days}
                                subtitle={item.subtitle}
                              />
                            );
                          })} */}
                        {payslips
                          .find(({ key }) => key === "current_pay")
                          ?.value?.filter(
                            (item) => item.key !== "total_earning"
                          )
                          ?.map((item, index) => {
                            return (
                              <ChartDetail
                                key={index}
                                bgcolor={itemColors[index]}
                                value={Number(item.value)}
                                // days={item.days}
                                subtitle={item.label}
                              />
                            );
                          })}
                      </div>
                    </div>

                    <div>
                      <Typography
                        style={{ fontSize: "12px", color: "#111111CC" }}
                      >
                        Earning Details
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#111111CC",
                          }}
                        >
                          Net Take Home
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#111111CC",
                          }}
                        >
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          }).format(
                            payslips
                              .find(({ key }) => key === "current_pay")
                              ?.value?.find(({ key }) => key === "net_paid")
                              ?.value
                          )}
                        </Typography>
                      </div>
                    </div>

                    {payslips
                      .filter(
                        ({ key, attendancsummary }) =>
                          key !== "current_pay" && !attendancsummary
                      )
                      .map((payslip, index) => (
                        <MyAccordion
                          key={payslip}
                          title={payslip.label || "Attendance"}
                          bg_color={colors[index] || "#3CC1B1"}
                          icon={GrossSalaryIcon}
                          amount={payslip.value?.reduce(
                            (acc, curr) => acc + Number(curr?.value || 0),
                            0
                          )}
                        >
                          {payslip.label ? (
                            <GrossSalary data={payslip.value} />
                          ) : (
                            <Attendence
                              handleOpenQuery={this.handleOpenQuery}
                              days={payslip.attendance}
                              summary={
                                payslips.find(
                                  ({ attendancsummary }) => attendancsummary
                                ).attendancsummary
                              }
                              month={new Date(
                                new Date().setMonth(
                                  Number(this.state.selectedMonth) - 1
                                )
                              ).setYear(this.state.selectedYear)}
                            />
                          )}
                        </MyAccordion>
                      ))}
                    {/* <MyAccordion
                      title="Allowance"
                      bg_color="#3CC1B1"
                      icon={AllowanceIcon}
                    >
                      <Allowance />
                    </MyAccordion>
                    <MyAccordion
                      title="Deduction"
                      bg_color="rgba(211, 67, 67, 0.55)"
                      icon={DeductionIcon}
                    >
                      <Deduction />
                    </MyAccordion>
                    <MyAccordion
                      title="Attendence"
                      bg_color="#AEA2FC"
                      icon={AttendenceIcon}
                    >
                      <Attendence handleOpenQuery={this.handleOpenQuery} />
                    </MyAccordion> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    status:
      state.loginReducer.verifyDetails !== null
        ? state.loginReducer.verifyDetails.status
        : "",
    payslips: state.payslipReducers.payslipData?.data || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetPayslipDataAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(payslipStyle)(Payslip));
