const cardStyle = theme => ({
  card: {
    borderRadius: "8px",
    color: "#fff",
    padding: "25px 16px",
    background: 'linear-gradient(254.33deg, #296C45 0%, #296C45 33.56%, #ABBD3D 107.23%, #FFF138 112.01%)',
  }
});

export default cardStyle;
