import React, { Component } from 'react';
import Aux from '../utils/Auxilary';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, withRouter, Redirect, Route } from 'react-router-dom';
import UserLayout from '../templates/User/UserLayout';
import AuthLayout from '../templates/Auth/AuthLayout';
import { RefreshTokenAction } from '../pages/Login/actions/LoginActions';
import { role } from '../utils/utility';

class RoutesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false
    }
  }

  UNSAFE_componentWillMount() {
    this.props.RefreshTokenAction(localStorage.getItem('access_token'));
  }

  componentDidMount = () => {
    const accessToken = localStorage.getItem('access_token')
    if (!this.state.isLoggedIn && (accessToken === this.props.token)) {
      const isLoggedIn = true
      this.setState({ isLoggedIn });
    }
  }

  componentDidUpdate = () => {
    const accessToken = localStorage.getItem('access_token');
    if (!this.state.isLoggedIn && (accessToken === this.props.token)) {
      const isLoggedIn = true
      this.setState({ isLoggedIn });
    }
  }

  redirectRouteHandler = (role) => {
    if (
      role.toUpperCase() === "USER"
    ) {
      return (
        <Switch>
          <Route exact path="/">
            <Redirect exact from="/" to="/User/Payslip" />
          </Route>

          <Route path="**">
            <Redirect exact from="*" to="/User/Payslip" />
          </Route>
        </Switch>
      )
    } else {
      return;
    }
  }

  render() {
    const { isLoggedIn } = this.state;
    const { loading } = this.props;

    let routes = (
      <>
        {
          isLoggedIn ?
            <Switch>
              <Route path="/User" component={UserLayout} />
              {this.redirectRouteHandler(role)}
            </Switch>
            :
            <>
              {loading || localStorage.getItem("access_token")
                ?
                ''
                :
                <Switch>
                  <Route path="/Auth/Login" component={AuthLayout} />
                  <Redirect to="/Auth/Login" />
                </Switch>
              }
            </>
        }
      </>
    );
    return (
      <Aux>
        {routes}
      </Aux>
    )
  }
}


function mapStateToProps(state) {
  return {
    loadig: state.loginReducer.loading,
    isLoggedIn: state.loginReducer.isLoggedIn,
    token: state.loginReducer.loggedInUserDetails !== null ? state.loginReducer.loggedInUserDetails.data.token : '',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      RefreshTokenAction
    }, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoutesComponent));