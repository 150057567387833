const sliderStyle = theme => ({
  topContainer: {
    marginBottom: '50px'
  },
  container: {
    padding: '10px'
  },
  image: {
    borderRadius: '20px',
    margin: 'auto'
  }
})

export default sliderStyle;