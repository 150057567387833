import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#296C45",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: "fit-content",
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: `none !important`,
      },
    },
    MuiPickersBasePicker: {
      container: {
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      pickerView: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "fit-content",
        maxWidth: "fit-content",
        minHeight: `fit-content !important`,
        width: "fit-content",
      },
    },
    MuiPickersYearSelection: {
      container: {
        height: "213px",
        width: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowY: "auto",
        border: "1px solid #296C45",
        borderRadius: "24px",
        background: "#F0F5F2",
      },
    },
    MuiPickersMonth: {
      root: {
        flex: "1 0 20%",
        "&:focus": {
          color: "white",
        },
      },
      monthSelected: {
        background: "#296C45",
        color: "#fff",
      },
    },
    MuiPickersMonthSelection: {
      container: {
        display: "flex",
        flexDirection: "column",
        height: "213px",
        width: "150px",
        overflowY: "auto",
        border: "1px solid #296C45",
        borderRadius: "24px",
        background: "#F0F5F2",
        flexWrap: "none",
      },
    },
    MuiPickersYear: {
      root: {
        "&:focus": {
          color: "white",
        },
      },
      yearSelected: {
        background: "#296C45",
        color: "#fff",
      },
    },
  },
});

export default theme;
