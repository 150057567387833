
/**
 @desc
 * Centralized unique actions for Login Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  GET_PAYSLIP_DETAILS_BY_DATE,
  PayslipActionTypes
} from '../constants/index';
import { showErrorMessage } from '../../../utils/utility';


/**
* @param  {[object]} postData [The data needed as a payload for the API interaction]
*/
export const GetPayslipDataAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(PayslipActionTypes.get_PayslipDetailsByDate.REQUEST));
    fetch(`${GET_PAYSLIP_DETAILS_BY_DATE}`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(actionCreator(PayslipActionTypes.get_PayslipDetailsByDate.FAILURE, response));
          showErrorMessage(response.message)
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage('Session Timed Out', 3000);
          localStorage.clear();
          dispatch(actionCreator(PayslipActionTypes.get_PayslipDetailsByDate.FAILURE, response));
          window.location.reload();
        } else {
          dispatch(actionCreator(PayslipActionTypes.get_PayslipDetailsByDate.SUCCESS, response));
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(PayslipActionTypes.get_PayslipDetailsByDate.FAILURE));
        console.log('error get_PayslipDetailsByDate ..', error);
      });
  };
};
