import React from 'react';
import {
  withStyles,
  List,
  Typography
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import navBarStyle from 'assets/jss/material-dashboard-pro-react/components/navBarStyle';

const NavBar = ({ routes, ...rest }) => {
  const { classes } = rest;

  const activeRoute = routeName => {
    let currPathArr = window.location.pathname.split("/").filter(x => x && x !== "Admin");
    let path = routeName.split("/").filter(x => x);

    return currPathArr.includes(path[0])
  };

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {routes.map((route, index) => {
          return (
            <div className={classes.linkContainer} key={index}>
              <NavLink
                className={classes.navLink}
                activeClassName={classes.activeLink}
                to={route.layout + route.path}
              >
                {activeRoute(route.path) ? <img src={route.activeIcon} /> : <img src={route.icon} />}
                <Typography variant="caption">{route.name}</Typography>
              </NavLink>
            </div>
          )
        })}
      </List>
    </div>
  );
}

export default withStyles(navBarStyle)(NavBar)
