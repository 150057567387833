import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  makeStyles,
  Button
} from "@material-ui/core";

import styles from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";

const useStyles = makeStyles(styles);

const CustomButtons = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    secondary,
    primary,
    danger,
    className,
    children,
    fullWidth,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes.primary]: primary,
    [classes.secondary]: secondary,
    [classes.danger]: danger,
    [classes.fullWidth]: fullWidth,
    [className]: className
  });
  return (
    <Button {...rest} ref={ref} classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
});

CustomButtons.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

export default CustomButtons;
