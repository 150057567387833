const bankAccountDetailsStyle = theme => ({
  card: {
    padding: '20px',
    marginTop: '1rem',
    height: '179px',
    border: 'none',
    background: '#F7F7F7',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.15)',
  },
  cardLabel: {
    color: '#000',
    fontSize: '18px'
  },
  cardNumber: {
    color: '#000',
    fontSize: '18px',
    marginLeft: '0.5rem'
  },
  cardName: {
    color: '#000',
    fontSize: '16px'
  },
  IFSCCode: {
    color: '#000',
    opacity: 0.6,
    fontSize: '16px'
  },
  bankName: {
    color: '#000',
    marginLeft: '0.5rem',
    fontSize: '16px'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  topContainer: {
    padding: '20px'
  },
  subContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bottomContainer: {
    display: 'flex',
    padding: '50px 0 30px 0'
  },
  autoMargin: {
    margin: 'auto'
  },
  icon: {
    color: '#000'
  }
})

export default bankAccountDetailsStyle;